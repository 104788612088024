import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore,  } from "firebase/firestore"
import { getFunctions } from "firebase/functions"

// const firebaseApp = initializeApp({
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   appId: process.env.FIREBASE_APP_ID,
// });

export const firebaseConfig = {
  apiKey: "AIzaSyCp9xtb3hgXCB1OElpgjUbVHrEOycra9nM",
  authDomain: "lyf-registration.firebaseapp.com",
  projectId: "lyf-registration",
  storageBucket: "lyf-registration.appspot.com",
  messagingSenderId: "753543901452",
  appId: "1:753543901452:web:93a5efc08bcdcac98b1929",
  measurementId: "G-WNK9SSQQWF"
}

// I feel like this will need to be hidden in process.env eventually but for now since this is a private-unpublished repo I'll keep it like this.
const firebaseApp = initializeApp(firebaseConfig)

export default firebaseApp

export const firebaseAuth = getAuth(firebaseApp)
export const firestore = getFirestore(firebaseApp)
export const prodFirestore = getFirestore(firebaseApp)
export const testFirestore = getFirestore(firebaseApp, "internal-test")
export const firebaseFunctions = getFunctions(firebaseApp, "us-west2")
