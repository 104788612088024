import React from "react"
import { AppBar, Stack, Toolbar } from "@mui/material"

import SignInMenu from "@components/Auth/SignInMenu"
import { LinkButton } from "@components/Button"
import { ProdContext, ToggleProdContext } from "@components/ProdContext"
import AuthContext from "@components/Auth/AuthContext"

/**
 * This component will be displayed at the top of every page.
 */
export default function Header() {
  const { isAdmin } = React.useContext(AuthContext)

  return (
    <AppBar position="static" color={"default"}>
      <Toolbar>
        <Stack
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{ width: 1, paddingLeft: 4, paddingRight: 4 }}
        >
          {isAdmin && <ToggleProdContext label="Test Data" />}
          <LinkButton to="/" color="inherit">
            Home
          </LinkButton>
          <SignInMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
