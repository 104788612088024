exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-preregistration-tsx": () => import("./../../../src/pages/preregistration.tsx" /* webpackChunkName: "component---src-pages-preregistration-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../../../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-time-gate-tsx": () => import("./../../../src/pages/time-gate.tsx" /* webpackChunkName: "component---src-pages-time-gate-tsx" */),
  "component---src-pages-user-profile-tsx": () => import("./../../../src/pages/user/profile.tsx" /* webpackChunkName: "component---src-pages-user-profile-tsx" */)
}

